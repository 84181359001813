import logo from "../assets/logolight.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";
import facebook from "../assets/facebook.png";
import phone from "../assets/phone.png";
import whatsapp from "../assets/whatsapp.png";
import mail from "../assets/mail.png";
import time from "../assets/time.png";
import ellipse from "../assets/footerellipsis.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="md:px-[100px] px-5 md:pt-[70px] pt-[50px] pb-[20px] bg-[#182A3E] relative">
      <div className="flex md:flex-row flex-col justify-between  pb-[46px] border-b border-[#E1E1E11f]">
        <div className="flex flex-col max-w-[283px] md:items-start max-md:mx-auto items-center">
          <img src={logo} />
          <p className="text-base leading-[26px] text-white roboto-regular mt-6 max-md:text-center">
            Founded with the vision of revolutionizing the trading experience,
            our company is a leading innovator in the development of
            state-of-the-art trading bots. 
          </p>
          <div className="mt-[17px] flex flex-row gap-5">
            <a href="#" target="_blank">
              <img src={twitter} />
            </a>
            <a href="#" target="_blank">
              <img src={linkedin} />
            </a>
            <a href="#" target="_blank">
              <img src={facebook} />
            </a>
          </div>
        </div>
        <div className="md:hidden flex flex-row justify-between mt-[63px]">
          <div className="max-w-[184px] flex flex-col gap-4 ">
            <p className="text-[16px] text-white roboto-medium">Company</p>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}dashboard`}
              className="transition-all"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Trading Bot
              </p>
            </Link>
            <Link to="/features" className="transition-all">
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Features
              </p>
            </Link>
            <Link to="/plan" className="transition-all">
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Plans
              </p>
            </Link>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}dashboard/referrals`}
              className="transition-all"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Referrals
              </p>
            </Link>
            <a href="#supportcontacts" className="transition-all">
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Support / Contact
              </p>
            </a>
          </div>
          <div className="max-w-[217px] flex flex-col gap-4  ">
            <p className="text-[16px] text-white roboto-medium">
              Links Support
            </p>
            <Link
              to="/assets/compliances/Agreement - PRIVACY POLICY.pdf"
              target="_blank"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Privacy Policy
              </p>
            </Link>
            <Link
              to="/assets/compliances/Agreement%20-%20TERMS%20OF%20SERVICE.pdf"
              target="_blank"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Terms of Service
              </p>
            </Link>
            <Link
              to="/assets/compliances/Agreement%20-%20RISK%20DISCLOSURE%20AGREEMENT.pdf"
              target="_blank"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                Risk Disclosure
              </p>
            </Link>
            <Link
              to="/assets/compliances/Agreement%20-%20CLIENT%20AGREEMENT.pdf"
              target="_blank"
            >
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                User Agreement
              </p>
            </Link>
            <a href="#faqs" className="transition-all">
              <p className="text-[16px] text-white opacity-70 roboto-regular">
                FAQs
              </p>
            </a>
          </div>
        </div>
        <div className="max-w-[184px] flex flex-col gap-4 max-md:hidden">
          <p className="text-[16px] text-white roboto-medium">Company</p>
          <Link
            to={`${process.env.REACT_APP_BACKEND_URL}dashboard`}
            className="transition-all"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Trading Bot
            </p>
          </Link>
          <Link to="/features" className="transition-all">
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Features
            </p>
          </Link>
          <Link to="/plan" className="transition-all">
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Plans
            </p>
          </Link>
          <Link
            to={`${process.env.REACT_APP_BACKEND_URL}dashboard/referrals`}
            className="transition-all"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Referrals
            </p>
          </Link>
          <a href="#supportcontacts" className="transition-all">
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Support / Contact
            </p>
          </a>
        </div>
        <div className="max-w-[217px] flex flex-col gap-4  max-md:hidden">
          <p className="text-[16px] text-white roboto-medium">Links Support</p>

          <Link
            to="/assets/compliances/Agreement - PRIVACY POLICY.pdf"
            target="_blank"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Privacy Policy
            </p>
          </Link>
          <Link
            to="/assets/compliances/Agreement%20-%20TERMS%20OF%20SERVICE.pdf"
            target="_blank"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Terms of Service
            </p>
          </Link>
          <Link
            to="/assets/compliances/Agreement%20-%20RISK%20DISCLOSURE%20AGREEMENT.pdf"
            target="_blank"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              Risk Disclosure
            </p>
          </Link>
          <Link
            to="/assets/compliances/Agreement%20-%20CLIENT%20AGREEMENT.pdf"
            target="_blank"
          >
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              User Agreement
            </p>
          </Link>
          <a href="#faqs" className="transition-all">
            <p className="text-[16px] text-white opacity-70 roboto-regular">
              FAQs
            </p>
          </a>
        </div>
        <div className="max-w-[237px] max-md:mt-[37px] flex flex-col gap-4">
          <p className="text-[16px] text-white roboto-medium">CONTACT</p>
          <div className="flex flex-row items-start gap-2">
            <img src={phone} />
            <p className="text-[16px] leading-[22px] text-white opacity-70 roboto-regular">
              +1 833 461 3717
              <br />
              +1 218 718 2525
            </p>
          </div>
          <div className="flex flex-row items-start gap-2">
            <img src={whatsapp} />
            <p className="text-[16px] my-auto leading-[22px] text-white opacity-70 roboto-regular">
              Whatsapp
            </p>
          </div>
          <div className="flex flex-row items-start gap-2">
            <img src={mail} />
            <p className="text-[16px] my-auto leading-[22px] text-white opacity-70 roboto-regular">
              support@aisystemics.com
            </p>
          </div>
          <div className="flex flex-row items-start gap-2">
            <img src={time} />
            <p className="text-[16px] leading-[22px] text-white opacity-70 roboto-regular">
              Monday to Friday
              <br />
              09:00 AM to 8:00 PM
            </p>
          </div>
          <div className="text-[16px] leading-[22px] text-white opacity-70 roboto-regular">
            10325 Kensington Pkwy <br />
            P.O. Box 526
            <br />
            Kensington, MD 20895
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col gap-8 border-b border-[#E1E1E11f]">
        <div className="flex flex-col gap-2">
          <p className="text-[12px] leading-[18px] opacity-70 text-white roboto-regular">
            AI Systemics LLC, USA
          </p>
          <p className="text-[12px] leading-[18px] opacity-70 text-white roboto-regular">
            10325 Kensington Pkwy <br />
            P.O. Box 526
            <br />
            Kensington, MD 20895
          </p>
          <p className="text-[12px] leading-[18px] opacity-70 text-white roboto-regular">
            Registered in the State of Maryland, Montgomery County.
            <br /> Legal Information
            <br /> AI Systemics LLC is a U.S.-based company providing
            algorithmic trading solutions. The company is in compliance with all
            applicable laws and regulations within the United States and the
            State of Maryland.
          </p>
        </div>
        <p className="text-[12px] leading-[18px] opacity-70 text-white roboto-regular">
          The information contained on this website does not constitute
          investment advice. The risk of trading in securities markets,
          including futures and options, can be substantial. You must review and
          agree to our Disclaimers and Terms and Conditions before using this
          site.
          <br />
          <br />
          U.S. Government Required Disclaimer – Commodity Futures Trading
          Commission: Futures and options trading carries large potential
          rewards but also significant risks. You must be aware of these risks
          and be willing to accept them before investing in the futures and
          options markets. Do not trade with money you cannot afford to lose.
          This website is neither a solicitation nor an offer to buy or sell
          futures or options. No representation is being made that any account
          will or is likely to achieve profits or losses similar to those
          discussed on this website. The past performance of any trading system
          or methodology is not necessarily indicative of future results.
          <br />
          <br />
          Individual results may vary, and testimonials are not claimed to
          represent typical results.
          <br />
          <br />
          All testimonials are by real clients and may not reflect the typical
          user's experience. They are not intended to represent or guarantee
          that anyone will achieve the same or similar results.
          <br />
          <br />
          CFTC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE
          CERTAIN LIMITATIONS: Unlike an actual performance record, simulated
          results do not represent actual trading. Since the trades have not
          been executed, the results may have under-or-over compensated for the
          impact of certain market factors, such as lack of liquidity. Simulated
          trading programs are subject to the fact that they are designed with
          the benefit of hindsight. No representation is being made that any
          account will or is likely to achieve profit or losses similar to those
          shown.
          <br />
          <br />
        </p>
      </div>
      <p className="mt-[34px] text-[12px] leading-[18px] opacity-70 text-white roboto-regular">
        © 2024 AI Systemics LLC. ALL RIGHTS RESERVED. Reproduction without
        permission is prohibited.
      </p>
      <img
        src={ellipse}
        className="absolute right-0 bottom-0 max-md:w-[90px]"
      />
    </div>
  );
};